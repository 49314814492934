import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { DialogPromptApi, DialogPromptSpeakWithGrade } from '../api/DialogPromptApi';
import ChatBubble from './ChatBubble';

import AddSelfStudyBlueIcon from '../asset/icon-add-self-study-blue.png';
import MicrophoneBlueIcon from '../asset/icon-microphone-blue.png';
import RightArrowBlueIcon from '../asset/icon-right-arrow-blue.png';
import SpeakerBlueIcon from '../asset/icon-speaker-blue.svg';
import AudioWaveIcon from '../asset/icons8-audio-wave.gif';

import { useHistory } from 'react-router-dom';
import { sentenceScoreAllGood, sentenceScoreAnyFailed } from '../Score';
import { AudioApi } from '../api/AudioApi';
import { CustomSentenceApi } from '../api/CustomSentenceApi';
import { ISentenceScore, ScoreApi } from '../api/ScoreApi';
import { ISentence, SentenceApi } from '../api/SentenceApi';
import ErrorMessageContext from '../context/ErrorMessageContext';

interface DialogPromptSpeakWithGradeComponentProps {
    dialogPrompt: DialogPromptSpeakWithGrade;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}


const DialogPromptSpeakWithGradeComponent: React.FC<DialogPromptSpeakWithGradeComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const [teacherChinesePromptSentence, setTeacherChinesePromptSentence] = React.useState<ISentence | null>(null);

    React.useEffect(() => {
        if (dialogPrompt.teacher_chinese_prompt_sentence_id) {
            SentenceApi.getById(dialogPrompt.teacher_chinese_prompt_sentence_id)
                .then((response) => {
                    setTeacherChinesePromptSentence(response.data)
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.message);
                    }
                })
        }
    }, [])

    const [teacherEnglishChatBubbleDone, setTeacherEnglishChatBubbleDone] = React.useState(false);
    const [teacherImageDone, setTeacherImageDone] = React.useState(false);

    const imageRef = React.useRef<HTMLImageElement>(null);

    React.useEffect(() => {
        if (teacherEnglishChatBubbleDone) {
            // console.log("teacherEnglishChatBubbleDone=true");
            if (dialogPrompt.image_src && dialogPrompt.image_src != "broken") {
                // console.log("dialogPrompt.image_src")
                // make sure to scroll after the image is loaded.
                const handleImageLoad = () => {
                    setTeacherImageDone(true);
                };

                if (imageRef.current) {
                    if (imageRef.current.complete && imageRef.current.naturalHeight !== 0) {
                        // If image is already loaded
                        handleImageLoad();
                    } else {
                        // Image is not yet loaded, attach event listener
                        imageRef.current.addEventListener('load', handleImageLoad);
                    }
                }

                return () => {
                    // console.log("dialogPrompt.image_src return")
                    if (imageRef.current) {
                        // console.log("dialogPrompt.image_src return removeEventListener")
                        imageRef.current.removeEventListener('load', handleImageLoad);
                    }
                };
            } else {
                setTeacherImageDone(true);
            }
        }
    }, [teacherEnglishChatBubbleDone])


    const [teacherChineseChatBubbleDone, setTeacherChineseChatBubbleDone] = React.useState(false);

    React.useEffect(() => {
        if (teacherImageDone) {
            // console.log("teacherImageDone=true");
            if (teacherChinesePromptSentence && teacherChinesePromptSentence.text) {
                setPromptInputComponent(
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={handleTeacherChinesePromptSpeakerClick}>
                            <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px' }} /> F
                        </IconButton>
                    </div>
                )
            } else {
                setTeacherChineseChatBubbleDone(true)
            }
        }
    }, [teacherImageDone])

    const handleTeacherChinesePromptSpeakerClick = () => {
        if (teacherChinesePromptSentence == null) return;

        const teacher = localStorage.getItem('teacher');
        let audioPromise;

        if (teacher == "male") {
            audioPromise = AudioApi.getMale(teacherChinesePromptSentence.id)
        } else if (teacher == 'female') {
            audioPromise = AudioApi.getFemale(teacherChinesePromptSentence.id)
        } else if (teacher == 'female2') {
            audioPromise = AudioApi.getFemale2(teacherChinesePromptSentence.id)
        } else {
            throw Error("unknown selected teacher name in local storage " + teacher);
        }

        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.addEventListener('ended', () => { setTeacherChineseChatBubbleDone(true) }); // Listen to the 'ended' event
                audio.play();
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    };

    function isStudentResponsePromptEmpty(prompt: string | null) {
        return prompt == null || prompt == undefined || prompt.trim().toLowerCase() == "<answer/>"
    }

    React.useEffect(() => {
        if (teacherChineseChatBubbleDone) {
            if (isStudentResponsePromptEmpty(dialogPrompt.student_response_prompt)) {
                // skip student chat bubble if there is none
                setStudentChatBubbleDone(true);
            }
        }
    }, [teacherChineseChatBubbleDone])

    const [studentChatBubbleDone, setStudentChatBubbleDone] = React.useState(false);

    React.useEffect(() => {
        if (studentChatBubbleDone) {
            // console.log("studentChatBubbleDone=true");
            // need to scroll after this effect for some reason.
            // scrolling didn't work after teacherChineseChatBubbleDone
            if (imageRef.current) {
                // console.log("studentChatBubbleDone imageRef scrollIntoView")
                imageRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            if (chatBubbleContainerRef.current) {
                chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                // console.log("studentChatBubbleDone, chatBubbleContainerRef, scrollIntoView")
            }
        }
    }, [studentChatBubbleDone])

    const [addSentenceDialogOpen, setAddSentenceDialogOpen] = React.useState(false);
    const [addSentenceSuccessMessage, setAddSentenceSuccessMessage] = React.useState('');
    const [studentCorrectResponseSentenceId, setStudentCorrectResponseSentenceId] = React.useState(-1);

    const handleAddSentenceClick = () => {
        setAddSentenceDialogOpen(true);
    };

    const handleConfirm = () => {
        const sentenceId = studentCorrectResponseSentenceId
        CustomSentenceApi.createBySentenceId(sentenceId)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error('Unable to create sentence statuscode=' + response.status + " " + response.statusText);
            })
            .then(list => {
                // Update success message
                setAddSentenceSuccessMessage('Sentence added successfully!');
                // Don't Close the dialog, allow user to see success message
                // setAddSentenceDialogOpen(false);
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else if (err.response.status == 490) {
                    setAddSentenceSuccessMessage("Please validate your email before adding sentences. You can validate your email in your profile.");
                } else if (err.response.status == 491) {
                    setAddSentenceSuccessMessage(err.response['data']);
                } else if (err.response.status == 492) {
                    setAddSentenceSuccessMessage("This user is read only.");
                } else {
                    console.error(err);
                    setAddSentenceSuccessMessage(err.message);
                }
            })
    };

    const handleClose = () => {
        setAddSentenceDialogOpen(false);
        setAddSentenceSuccessMessage('')
    };

    const [isRecording, setIsRecording] = React.useState(false);
    const [isScoring, setIsScoring] = React.useState(false);

    const [displayHintWithoutScore, setDisplayHintWithoutScore] = React.useState(false);

    const [sentenceScoreList, setSentenceScoreList] = React.useState<ISentenceScore[]>([]);
    const [chatBubbleTeacherResponseDone, setChatBubbleTeacherResponseDone] = React.useState<boolean[]>([]);
    const [tryAgainClickedList, setTryAgainClickedList] = React.useState<(boolean | null)[]>([]);

    const [chatBubbleTryAgainDone, setChatBubbleTryAgainDone] = React.useState(false);
    const [chatBubbleContinueDone, setChatBubbleContinueDone] = React.useState(false);

    React.useEffect(() => {
        if (chatBubbleTryAgainDone == false) {
            if (sentenceScoreList.length == 0 || sentenceScoreList[sentenceScoreList.length - 1].passing_grade == false || tryAgainClickedList[sentenceScoreList.length - 1]) {
                setPromptInputComponent(
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        {/* Left-aligned X icon */}
                        <IconButton onClick={handleCancelClick}>
                            <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                        </IconButton>
                        <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center', flexGrow: 1 }} spacing={2}>
                            {/* <IconButton onClick={handleSpeakerClick}>
                                <VolumeUpRoundedIcon fontSize="large" />
                            </IconButton> */}
                            <IconButton onClick={isRecording ? stopRecording : startRecording}>
                                {isRecording ? <img src={AudioWaveIcon} alt="Recording" style={{ width: '32px', height: '32px' }} />
                                    : isScoring ? <CircularProgress size={24} sx={{ width: '24px', height: '24px' }} />
                                        : <img src={MicrophoneBlueIcon} style={{ width: '36px', height: '36px' }} />}
                            </IconButton>
                        </Stack>
                        {studentCorrectResponseSentenceId >= 0 && (
                            <Tooltip title="Add to Self-Study" arrow>
                                <IconButton onClick={handleAddSentenceClick} sx={{ alignSelf: 'center' }}>
                                    <img src={AddSelfStudyBlueIcon} style={{ width: '36px', height: '36px' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                )
            }
        }
    }, [studentChatBubbleDone, sentenceScoreList, isRecording, isScoring, chatBubbleTryAgainDone, tryAgainClickedList]);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            // console.log("sentenceScoreList, scrollIntoView")
        }
    }, [sentenceScoreList]);

    const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);

    let audioData: Blob | null = null;

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                // console.log("startRecording() 1")
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.addEventListener('dataavailable', handleAudioDataAvailable);
                mediaRecorderRef.current.addEventListener('stop', handleAudioDone);

                mediaRecorderRef.current.start();

                setIsRecording(true);
                // console.log("startRecording() 2")
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('Error accessing microphone:' + error.toString())
            });
    };
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            // console.log("stopRecording() 1")
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.removeEventListener('dataavailable', handleAudioDataAvailable);
            mediaRecorderRef.current.removeEventListener('stop', handleAudioDone);
            // console.log("stopRecording() 2")
        }

        setIsRecording(false);
    };

    const handleAudioDataAvailable = (event: BlobEvent) => {
        // console.log("handleAudioDataAvailable() 1")
        if (event.data.size > 0) {
            // console.log("event.data.size > 0")
            audioData = event.data;
        }
    };

    const [studentAnswer, setStudentAnswer] = React.useState<string | null>('');

    const handleAudioDone = () => {
        // console.log("handleAudioDone() 1")
        if (audioData) {
            // console.log("handleAudioDone() has audio data 1")
            setIsScoring(true)
            const formData = new FormData();
            formData.append('audio', audioData);

            // console.log("handle audio done")

            DialogPromptApi.getDialogPromptSpeakWithGradeMatch(dialogPrompt.id, formData)
                .then((response) => {
                    setIsScoring(false)
                    const closestResponseScore = response.data
                    // console.log("handleAudioDone, displayHintWithoutScore= " + displayHintWithoutScore)

                    // wierd bug with DisplayHintWithoutScore that is updating async
                    // check withint setState so we have the correct value of DisplayHintWithoutScore
                    setDisplayHintWithoutScore(prevDisplayHint => {
                        // if text doesn't match, then show hint
                        if (closestResponseScore.likely_text != closestResponseScore.text && dialogPrompt.teacher_hint_prompt != null && prevDisplayHint == false) {
                            return true
                        } else { // else display 
                            closestResponseScore.audioData = audioData;
                            setStudentAnswer(closestResponseScore.text)
                            setStudentCorrectResponseSentenceId(closestResponseScore.sentence_id)
                            setSentenceScoreList((previous) => [...previous, closestResponseScore]);
                            return prevDisplayHint
                        }
                    });
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.message);
                    }
                })
        }
    };
    React.useEffect(() => {
        if (studentCorrectResponseSentenceId > 0 && audioData) {
            const formData = new FormData();
            formData.append('audio', audioData);
            ScoreApi.getScore(studentCorrectResponseSentenceId, formData)
                .then(response => {
                    setIsScoring(false)
                    // check if the response was successful
                    if (response.status >= 300) {
                        throw new Error('Scoring audio was not ok statuscode=' + response.status + " " + response.statusText);
                    }
                    return response.data;
                })
                .then(responseJson => {
                    responseJson.audioData = audioData;
                    setSentenceScoreList((previous) => [...previous, responseJson]);
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.message);
                    }
                })
        }
    }, [studentCorrectResponseSentenceId])

    React.useEffect(() => {
        if (chatBubbleTryAgainDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center">
                    {/* Left-aligned X icon */}
                    <IconButton onClick={handleCancelClick}>
                        <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                    </IconButton>

                    {/* Center-aligned buttons */}
                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }} spacing={2}>
                        <Button variant="contained" onClick={handleNextWordClick} className="secondaryButton" >
                            No, Continue On
                        </Button>
                        <Button variant="contained" onClick={handleTryAgainClick} >
                            Yes, Try Again
                        </Button>
                    </Stack>
                </Stack>

            )
        }
    }, [chatBubbleTryAgainDone]);

    const handleNextWordClick = () => {
        setChatBubbleTryAgainDone(false)
        updateTryAgainClickedList(false)
    }
    const handleTryAgainClick = () => {
        setChatBubbleTryAgainDone(false)
        updateTryAgainClickedList(true)
    }

    const updateTryAgainClickedList = (tryAgain: boolean) => {
        const index = sentenceScoreList.length - 1;
        setTryAgainClickedList(previous => {
            const updatedArray = [...previous];
            updatedArray[index] = tryAgain;
            return updatedArray;
        })
    }

    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);

    const handleCancelClick = () => {
        setOpenCancelDialog(true);
    };

    const handleCancelDialogConfirm = () => {
        setOpenCancelDialog(false);
        setChatBubbleContinueDone(true);
    };

    const handleCancelDialogCancel = () => {
        setOpenCancelDialog(false);
    };

    React.useEffect(() => {
        if (chatBubbleContinueDone) {
            setPromptInputComponent(<div />)
            setDialogPromptComplete(dialogPrompt.id)

        }
    }, [chatBubbleContinueDone])

    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                showAvatar={showAvatar}
                chatBubbleContainerRef={chatBubbleContainerRef}
                setChatBubbleDone={setTeacherEnglishChatBubbleDone}
            />
            {(teacherEnglishChatBubbleDone && dialogPrompt.image_src) && (<>
                <img
                    ref={imageRef}
                    src={dialogPrompt.image_src}
                    alt={dialogPrompt.teacher_chinese_prompt ? dialogPrompt.teacher_chinese_prompt : ""}
                    style={{
                        maxHeight: '200px', maxWidth: '200px',
                        height: 'auto', width: 'auto',
                        margin: '0 auto', // Add this line to center the image horizontally
                        display: 'block', // Add this line to ensure the margin works correctly
                    }}
                />
            </>)}
            {(teacherImageDone && teacherChinesePromptSentence && teacherChinesePromptSentence.text) && (<>
                <ChatBubble
                    isTeacher={true}
                    message={teacherChinesePromptSentence && teacherChinesePromptSentence.text ? teacherChinesePromptSentence.text : ''}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    // setChatBubbleDone={setTeacherChineseChatBubbleDone}
                    useChineseFont={true}
                />
            </>)}
            {(teacherChineseChatBubbleDone && !isStudentResponsePromptEmpty(dialogPrompt.student_response_prompt)) && (
                <ChatBubble
                    isTeacher={false}
                    message={dialogPrompt.student_response_prompt ? dialogPrompt.student_response_prompt : ""}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setStudentChatBubbleDone}
                    useChineseFont={true}
                />
            )}
            {(displayHintWithoutScore && dialogPrompt.teacher_hint_prompt != null) && (
                <ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.teacher_hint_prompt}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    useChineseFont={true}
                />
            )}

            {sentenceScoreList.map((sentenceScore, index) => (
                <div key={index}>
                    <ChatBubble
                        isTeacher={false}
                        message={sentenceScore.text}
                        sentenceScore={sentenceScore}
                        showAvatar={showAvatar}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                    {sentenceScoreAllGood(sentenceScore) ? (
                        <ChatBubble
                            isTeacher={true}
                            message={dialogPrompt.yes_response}
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            setChatBubbleDone={setChatBubbleContinueDone}
                        />
                    ) : sentenceScoreAnyFailed(sentenceScore) ? (
                        <ChatBubble isTeacher={dialogPrompt.is_teacher}
                            message={dialogPrompt.no_response}
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            setChatBubbleDone={(done) => {
                                setChatBubbleTryAgainDone(false);
                                // set null, to skip this index
                                setTryAgainClickedList((previous) => [...previous, null]);
                            }}
                        />
                    ) : (
                        <ChatBubble isTeacher={dialogPrompt.is_teacher}
                            message={"You're on the right track!"}
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            setChatBubbleDone={(done) => setChatBubbleTeacherResponseDone(previous => {
                                const updatedArray = [...previous];
                                updatedArray[index] = done;
                                return updatedArray;
                            })}
                        />
                    )}
                    {chatBubbleTeacherResponseDone[index] && (
                        <ChatBubble
                            isTeacher={true}
                            message="Would you like to try again?"
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            setChatBubbleDone={setChatBubbleTryAgainDone}
                        />
                    )}
                    {tryAgainClickedList[index] === true && (
                        <ChatBubble
                            isTeacher={false}
                            message="I will try again"
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                        />
                    )}
                    {tryAgainClickedList[index] === false && (
                        <ChatBubble
                            isTeacher={false}
                            message="No, thank you"
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            setChatBubbleDone={setChatBubbleContinueDone}
                        />

                    )}
                </div>
            ))}
            {/* Confirmation Dialog */}
            <Dialog open={openCancelDialog} onClose={handleCancelDialogCancel}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirm Skip</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Are you sure you want to skip this exercise?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleCancelDialogCancel} color="primary" sx={{ color: '#787878' }}>
                        Keep trying
                    </Button>
                    <Button onClick={handleCancelDialogConfirm} color="primary" sx={{ color: '#787878' }}>
                        Skip
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Add Sentence Confirmation Dialog */}
            <Dialog open={addSentenceDialogOpen} onClose={handleClose}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirmation</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Do you want to add this sentence to Self-Study?
                        <p>{studentAnswer}</p>
                    </DialogContentText>
                    {/* Display success message inside the Dialog */}
                    {addSentenceSuccessMessage && <p>{addSentenceSuccessMessage}</p>}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleClose} color="primary" sx={{ color: '#787878' }}>
                        {addSentenceSuccessMessage.length == 0 ? "Cancel" : "Close"}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" disabled={addSentenceSuccessMessage.length > 0} sx={{ color: '#787878' }}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack >
    );
};

export default DialogPromptSpeakWithGradeComponent;
