import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface ILesson {
    id: number;
    course_id: number;
    order_index: number;
    name: string;
    description: string | null;
    expanded?: boolean;
}

export const LessonApi = {
    getAll: function (courseId?: number) {
        return axiosInstance.request<ILesson[]>({
            method: "GET",
            url: `/lesson${courseId ? `?course_id=${courseId}` : ''}`
        });
    },
    getById: function (lessonId: number) {
        return axiosInstance.request<ILesson>({
            method: "GET",
            url: `/lesson/${lessonId}`
        });
    },
    create: function (lesson: ILesson) {
        return axiosInstance.request<ILesson[]>({
            method: "POST",
            url: `/lesson`,
            data: lesson
        });
    },
    update: function (lesson: ILesson) {
        return axiosInstance.request<ILesson[]>({
            method: "PUT",
            url: `/lesson`,
            data: lesson
        });
    },
    delete: function (lessonId: number) {
        return axiosInstance.request<ILesson[]>({
            method: "DELETE",
            url: `/lesson/${lessonId}`,
        });
    },
}
