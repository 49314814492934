import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogApi, IDialog } from '../api/DialogApi';
import ErrorMessageContext from '../context/ErrorMessageContext';
import { ILesson, LessonApi } from '../api/LessonApi';
import { CourseApi, ICourse } from '../api/CourseApi';

interface DialogFormProps {
    dialogId: number;
    onSubmit: (updatedDialog: IDialog) => void;
    onDelete: (dialogId: number) => void;
}

const DialogForm: React.FC<DialogFormProps> = ({ dialogId, onSubmit, onDelete }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [id, setId] = React.useState(-1);
    const [lessonId, setLessonId] = React.useState(-1);
    const [courseDictionary, setCourseDictionary] = React.useState<{ [key: number]: ICourse; }>({}); // State to store course options
    const [lessonList, setLessonList] = React.useState<ILesson[]>([]);
    const [title, setTitle] = React.useState('');
    const [orderIndex, setOrderIndex] = React.useState(-1);
    const [showConfirmation, setShowConfirmation] = React.useState(false);

    React.useEffect(() => {
        const fetchCourseList = async () => {
            try {
                const response = await CourseApi.getAll();
                const courseList = response.data

                const courseDictionary = courseList.reduce((accumulator, currentCourse) => {
                    accumulator[currentCourse.id] = currentCourse;
                    return accumulator;
                  }, {} as { [key: number]: typeof courseList[number] }); // Using "typeof courseList[number]" to infer course type
                setCourseDictionary(courseDictionary)
                  
            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            }
        };
        const fetchLessonList = async () => {
            try {
                const response = await LessonApi.getAll();
                setLessonList(response.data);
            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            }
        };
        fetchCourseList();
        fetchLessonList();
    }, []);

    React.useEffect(() => {
        DialogApi.getById(dialogId)
            .then((response) => {
                const dialog = response.data;
                setId(dialog.id);
                setLessonId(dialog.lesson_id);
                setTitle(dialog.title);
                setOrderIndex(dialog.order_index);
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }, [dialogId]);

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setId(Number(e.target.value));
    };

    const handleLessonIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLessonId(Number(e.target.value));
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleOrderIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrderIndex(Number(e.target.value));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const updatedDialog: IDialog = {
            id,
            lesson_id: lessonId,
            order_index: orderIndex,
            title,
        };
        onSubmit(updatedDialog);
    };


    const handleDeleteConfirmation = () => {
        setShowConfirmation(true);
    };

    const handleDeleteCancel = () => {
        setShowConfirmation(false);
    };

    const handleDeleteConfirm = () => {
        setShowConfirmation(false);
        onDelete(dialogId);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
                <Typography variant="h5">
                    Edit Dialog
                </Typography>
                <input type="hidden" value={id} onChange={handleIdChange} />
                <input type="hidden" value={lessonId} onChange={handleLessonIdChange} />
                <TextField
                    label="Title"
                    value={title}
                    onChange={handleTitleChange}
                    required
                />
                <FormControl fullWidth>
                    <InputLabel>Parent Lesson Name</InputLabel>
                    <Select
                        value={lessonId}
                        label="Parent Lesson Name"
                        onChange={e => setLessonId(Number(e.target.value))}
                        required
                    >
                        <MenuItem key={-1} value={-1}/>
                        {lessonList.map((lesson) => (
                            <MenuItem key={lesson.id} value={lesson.id}>
                                {lesson.name} :: {courseDictionary[lesson.course_id] ? courseDictionary[lesson.course_id].name : "Course Deleted"}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Sequence Number"
                    value={orderIndex}
                    onChange={handleOrderIndexChange}
                    type="number"
                    required
                />
                <br />
                <Button type="submit" variant="contained">
                    Save
                </Button>
                <Button variant="contained" color="error" onClick={handleDeleteConfirmation}>
                    Delete
                </Button>
            </Stack>
            <Dialog open={showConfirmation} onClose={handleDeleteCancel}>
                <DialogTitle>Delete Dialog</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this dialog?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default DialogForm;
