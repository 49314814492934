import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface IComment {
    id: number;
    hsk_level: number | null;
    good_comment: string | null;
    bad_comment: string | null;
    bug_report: string | null;
}

export const CommentApi = {
    getAll: function () {
        return axiosInstance.request<IComment[]>({
            method: "GET",
            url: `/comment`
        });
    },
    getById: function (commentId: number) {
        return axiosInstance.request<IComment>({
            method: "GET",
            url: `/comment/${commentId}`
        });
    },
    create: function (comment: IComment) {
        return axiosInstance.request<IComment[]>({
            method: "POST",
            url: `/comment`,
            data: comment
        });
    },
    update: function (comment: IComment) {
        return axiosInstance.request<IComment[]>({
            method: "PUT",
            url: `/comment`,
            data: comment
        });
    },
    delete: function (commentId: number) {
        return axiosInstance.request<IComment[]>({
            method: "DELETE",
            url: `/comment/${commentId}`,
        });
    },
}
