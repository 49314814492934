import * as React from 'react';
import { ICustomSentence } from '../api/CustomSentenceApi';

export interface ISelectedCustomSentenceContextType {
  selectedSentence: ICustomSentence;
  setSelectedSentence: (newSentence: ICustomSentence) => void;
};

export const SelectedCustomSentenceInitialContext = { selectedSentence: { id: 0, text: '' }, setSelectedSentence: () => null };

const SelectedCustomSentenceContext = React.createContext<ISelectedCustomSentenceContextType>(SelectedCustomSentenceInitialContext);

export default SelectedCustomSentenceContext;
