import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

export interface ILogin {
    email: string | null;
    password: string | null;
    rememberMe: boolean | null;
}
export interface IRegister {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    referrerSentenceScoreId: string | null;
    referrerGuid: string | null;
}
export interface IToken {
    token: string;
}

export const LoginApi = {
    login: function (login: ILogin) {
        return axiosInstance.request<IToken>({
            method: "POST",
            url: `/login`,
            data: login
        });
    },
    register: function (register: IRegister) {
        return axiosInstance.request<boolean>({
            method: "POST",
            url: `/register`,
            data: register
        });
    },
    validateEmail: function (code: string) {
        // URL encode the code parameter
        const encodedCode = encodeURIComponent(code);

        // Construct the URL with the encoded code parameter
        return axiosInstance.request<boolean>({
            method: 'GET',
            url: `/validate_email?code=${encodedCode}`, // Use template literals to include the encoded code
        });
    },
    sendResetPasswordEmail: function (email: string) {
        // URL encode the code parameter
        const encodedEmail = encodeURIComponent(email);

        // Construct the URL with the encoded code parameter
        return axiosInstance.request<boolean>({
            method: 'GET',
            url: `/reset_password_email?email=${email}`, // Use template literals to include the encoded code
        });
    },
    resetPassword: function (code: string) {
        // URL encode the code parameter
        const encodedCode = encodeURIComponent(code);

        // Construct the URL with the encoded code parameter
        return axiosInstance.request<string>({
            method: 'GET',
            url: `/reset_password?code=${code}`, // Use template literals to include the encoded code
        });
    }
}
