import * as React from 'react';
import { ICustomSentence } from '../api/CustomSentenceApi';

export interface ICustomSentenceListContextType {
  customSentenceList: ICustomSentence[];
  setCustomSentenceList: (newCustomSentenceList: ICustomSentence[]) => void;
};

export const CustomSentenceListInitialContext = { customSentenceList: [], setCustomSentenceList: () => null };

const CustomSentenceListContext = React.createContext<ICustomSentenceListContextType>(CustomSentenceListInitialContext);

export default CustomSentenceListContext;
