import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import * as React from 'react';
import { ICustomSentence } from '../api/CustomSentenceApi';
import CustomSentenceMenu from '../component/CustomSentenceMenu';
import RecordAndScore from '../component/RecordAndScore';
import CustomSentenceListContext, { CustomSentenceListInitialContext } from '../context/CustomSentenceListContext';
import SelectedCustomSentenceContext, { SelectedCustomSentenceInitialContext } from '../context/SelectedCustomSentenceContext';

export default function CustomSentence() {

  const [selectedSentence, setSelectedSentence] = React.useState<ICustomSentence>(SelectedCustomSentenceInitialContext.selectedSentence)

  const [customSentenceList, setCustomSentenceList] = React.useState<ICustomSentence[]>(CustomSentenceListInitialContext.customSentenceList);

  return (
    <SelectedCustomSentenceContext.Provider value={{ selectedSentence, setSelectedSentence }}>
      <CustomSentenceListContext.Provider value={{ customSentenceList, setCustomSentenceList }}>
        <Grid container justifyContent="center">
          <Grid item sm={12} md={4} alignItems="center" justifyContent="center" className="secondaryBackground">
            <Container sx={{ mt: 1 }}>
              <CustomSentenceMenu />
            </Container>
          </Grid>
          <Grid item sm={12} md={8} alignItems="center" justifyContent="center">
            <RecordAndScore />
          </Grid>
        </Grid>
      </CustomSentenceListContext.Provider>
    </SelectedCustomSentenceContext.Provider>
  );
}
