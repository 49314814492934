import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export const AudioApi = {

    getMale: function (sentenceId: number) {
        return axiosInstance.request<Blob>({
            method: "GET",
            url: `/audio/male/${sentenceId}`,
            responseType: "blob"
        });
    },
    getFemale: function (sentenceId: number) {
        return axiosInstance.request<Blob>({
            method: "GET",
            url: `/audio/female/${sentenceId}`,
            responseType: "blob"
        });
    },
    getFemale2: function (sentenceId: number) {
        return axiosInstance.request<Blob>({
            method: "GET",
            url: `/audio/female2/${sentenceId}`,
            responseType: "blob"
        });
    },
    getSpeakerA: function (sentenceId: number) {
        return axiosInstance.request<Blob>({
            method: "GET",
            url: `/audio/speaker/${sentenceId}?name=A`,
            responseType: "blob"
        });
    },
    getSpeakerB: function (sentenceId: number) {
        return axiosInstance.request<Blob>({
            method: "GET",
            url: `/audio/speaker/${sentenceId}?name=B`,
            responseType: "blob"
        });
    },
    getPublicSentenceScoreAudio: function (publicSentenceScoreId: string) {
        return axiosInstance.request<Blob>({
            method: "GET",
            url: `/public_sentence_score_audio/${publicSentenceScoreId}`,
            responseType: "blob"
        });
    },
}
