import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#0062FF',
    },
    secondary: {
      main: '#FCCD56',
    },
    error: {
      main: '#F25C5C',
    },
    warning: {
      main: '#FCCD56',
    },
    success: {
      main: '#28A164'
    },
    background: {
      default: '#F2F7FF', // Your desired background color
      // secondary: '#002A6E', // New secondary background color
    },
  },
  // Override default properties
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // Change the border radius for text fields
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // Change the border radius for buttons
          paddingTop: '16px',
          paddingBottom: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '150%',
          textTransform: 'none',
          boxShadow: 'none', // Ensure no shadow is applied
          '&:hover': {
            boxShadow: 'none', // Also ensure no shadow on hover
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          // Style for all states
          textTransform: 'none', // Prevents uppercase transformation
          borderRadius: 16, // Rounded corners for all buttons
          color: '#1E1E1E', // Foreground color for the unselected button
          backgroundColor: '#FFFFFF',
          paddingTop: '6px',
          paddingBottom: '6px',
          '&.Mui-selected': {
            color: '#F2F7FF',
            backgroundColor: '#1E1E1E',
            '&:hover': {
              backgroundColor: '#1E1E1E', // Maintain the same color on hover
            },
          },
          // Apply this style to the first child ToggleButton
          '&:first-of-type': {
            paddingLeft: '16px'
          },
          // Apply this style to the last child ToggleButton
          '&:last-of-type': {
            paddingRight: '16px'
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 600, // Custom fontWeight
      fontSize: '48px', // Custom fontSize
    },
    h2: {
      fontWeight: 600,
      fontSize: '32px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '24px',
    },
    h4: {
      fontWeight: 600,
      fontSize: '20px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '16px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '14px',
    },
  },
});

export default theme;
