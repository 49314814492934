import * as React from 'react';
import { IChat } from '../api/ChatApi';

export interface IChatListContextType {
  chatList: IChat[];
  setChatList: (newChatList: IChat[]) => void;
};

export const ChatListInitialContext = { chatList: [], setChatList: () => null };

const ChatListContext = React.createContext<IChatListContextType>(ChatListInitialContext);

export default ChatListContext;
