import { Stack } from '@mui/material';
import React from 'react';
import { DialogPromptImage } from '../api/DialogPromptApi';
import ChatBubble from './ChatBubble';

interface DialogPromptImageComponentProps {
    dialogPrompt: DialogPromptImage;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptImageComponent: React.FC<DialogPromptImageComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    const imageRef = React.useRef<HTMLImageElement>(null);

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    React.useEffect(() => {

        if (dialogPrompt.prompt == null || dialogPrompt.prompt === "") {
            setChatBubbleDone(true);
        }

        setPromptInputComponent(null)
    }, []);

    React.useEffect(() => {
        if (chatBubbleDone) {
            if (dialogPrompt.image_src && dialogPrompt.image_src != "broken") {
                // make sure to scroll after the image is loaded.
                const handleImageLoad = () => {
                    if (chatBubbleContainerRef.current) {
                        chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                    setDialogPromptComplete(dialogPrompt.id)
                };

                if (imageRef.current) {
                    if (imageRef.current.complete && imageRef.current.naturalHeight !== 0) {
                        // If image is already loaded
                        handleImageLoad();
                    } else {
                        // Image is not yet loaded, attach event listener
                        imageRef.current.addEventListener('load', handleImageLoad);
                    }
                }

                return () => {
                    if (imageRef.current) {
                        imageRef.current.removeEventListener('load', handleImageLoad);
                    }
                };
            } else {
                setDialogPromptComplete(dialogPrompt.id)
            }
        }
    }, [chatBubbleDone]);

    return (
        <Stack>
            {dialogPrompt.prompt && (
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={dialogPrompt.prompt}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleDone}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            )}
            {chatBubbleDone && (
                <img
                    ref={imageRef}
                    src={dialogPrompt.image_src}
                    alt={dialogPrompt.prompt}
                    style={{
                        maxHeight: '400px', maxWidth: '400px',
                        height: 'auto', width: 'auto',
                        margin: '0 auto', // Add this line to center the image horizontally
                        display: 'block', // Add this line to ensure the margin works correctly
                    }}
                />
            )}
        </Stack>
    );
};

export default DialogPromptImageComponent;
