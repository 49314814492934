import axios from 'axios';
import { REST_HOSTNAME } from "../Config";
import { ISentenceScore } from './ScoreApi';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface ICustomSentence {
    id: number,
    text: string,
    processing_queue?: number
};

export const CustomSentenceApi = {
    getAll: function () {
        return axiosInstance.request<ICustomSentence[]>({
            method: "GET",
            url: `/custom_sentence`
        });
    },
    getById: function (customSentenceId: number) {
        return axiosInstance.request<ICustomSentence>({
            method: "GET",
            url: `/custom_sentence/${customSentenceId}`
        });
    },
    create: function (customSentence: ICustomSentence) {
        return axiosInstance.request<ICustomSentence[]>({
            method: "PUT",
            url: `/custom_sentence`,
            data: customSentence
        });
    },
    createBySentenceId: function (sentenceId: number) {
        return axiosInstance.request<ICustomSentence[]>({
            method: "PUT",
            url: `/custom_sentence/sentence/${sentenceId}`,
            data: sentenceId
        });
    },
    createPublic: function (publicSentenceScoreId: string) {
        return axiosInstance.request<ICustomSentence[]>({
            method: "PUT",
            url: `/custom_sentence/public/${publicSentenceScoreId}`
        });
    },
    delete: function (customSentenceId: number) {
        return axiosInstance.request<ICustomSentence[]>({
            method: "DELETE",
            url: `/custom_sentence/${customSentenceId}`,
        });
    },
    getMaleAudio: function (customSentenceId: number) {
        return axiosInstance.request<Blob>({
            method: "GET",
            url: `/audio/male/custom_sentence/${customSentenceId}`,
            responseType: "blob"
        });
    },
    getFemaleAudio: function (customSentenceId: number) {
        return axiosInstance.request<Blob>({
            method: "GET",
            url: `/audio/female/custom_sentence/${customSentenceId}`,
            responseType: "blob"
        });
    },
    getFemale2Audio: function (customSentenceId: number) {
        return axiosInstance.request<Blob>({
            method: "GET",
            url: `/audio/female2/custom_sentence/${customSentenceId}`,
            responseType: "blob"
        });
    },
    getScore: function (customSentenceId: number, audio: any) {
        return axiosInstance.request<ISentenceScore>({
            method: "PUT",
            url: `/sentence_score/custom_sentence/${customSentenceId}`,
            data: audio
        });
    }
}
