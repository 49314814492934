import { Stack, Typography } from '@mui/material';
import React from 'react';
import { ICourse } from '../api/CourseApi';

interface CoursePageProps {
    course: ICourse;
}

const CoursePage: React.FC<CoursePageProps> = ({ course }) => {

    const [name, setName] = React.useState(course.name);
    const [description, setDescription] = React.useState(course.description);

    React.useEffect(() => {
        setName(course.name);
        setDescription(course.description);
    }, [course]);

    return (
        <Stack spacing={4} sx={{ margin: '0 auto', pt: 2 }}>
            <Typography>
                {description}
            </Typography>
        </Stack>
    );
};

export default CoursePage;
