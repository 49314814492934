import * as React from 'react';
import { IChat } from '../api/ChatApi';

export interface ISelectedChatContextType {
  selectedChat: IChat;
  setSelectedChat: (newChat: IChat) => void;
};

export const SelectedChatInitialContext = { selectedChat: { id: 0, hsk_level: 0, first_line: '' }, setSelectedChat: () => null };

const SelectedChatContext = React.createContext<ISelectedChatContextType>(SelectedChatInitialContext);

export default SelectedChatContext;
