import * as React from 'react';

export interface IErrorMessageContextType {
  errorMessage: string;
  setErrorMessage: (newErrorMessage: string) => void;
};

export const ErrorMessageInitialContext = { errorMessage: "", setErrorMessage: () => null };

const ErrorMessageContext = React.createContext<IErrorMessageContextType>(ErrorMessageInitialContext);

export default ErrorMessageContext;
