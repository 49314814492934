import { ISentenceScore } from "./api/ScoreApi";

// Individual exports
export const SCORE_GOOD = 90;
export const SCORE_OK = 70;

export function scoreColor(score: number) {
    if (score >= SCORE_GOOD) return "#28A164"
    if (score >= SCORE_OK) return "#FCCD56"
    return "#F25C5C"
}


export function sentenceScoreAllGood(sentenceScore: ISentenceScore) {
    return sentenceScore.score.every(score => score >= SCORE_GOOD);
}

export function sentenceScoreAllOkAtLeastOk(sentenceScore: ISentenceScore) {
    return sentenceScore.score.every(score => score >= SCORE_OK);
}

export function sentenceScoreAnyFailed(sentenceScore: ISentenceScore) {
    return sentenceScore.score.some(score => score < SCORE_OK);
}

export function hexToRgbaColorString(hex: string, alpha: number): string {
    // Remove the hash at the start if it's there
    hex = hex.replace('#', '');

    // Parse the hex string into RGB values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return the RGBA color string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}