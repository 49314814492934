import { Button, Input, Stack } from '@mui/material';
import React from 'react';
import { DialogPromptSelectLengthTime } from '../api/DialogPromptApi';
import ErrorMessageContext from '../context/ErrorMessageContext';
import ChatBubble from './ChatBubble';
import SendButtonTextField from './SendButtonTextField';

interface DialogPromptSelectLengthTimeComponentProps {
    dialogPrompt: DialogPromptSelectLengthTime;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptSelectLengthTimeComponent: React.FC<DialogPromptSelectLengthTimeComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [selectLengthTime, setSelectLengthTime] = React.useState<string | null>('');
    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectLengthTime = event.target.value;
        setSelectLengthTime(selectLengthTime);
    };

    const handleTimeChange = () => {
        if (selectLengthTime) {
            localStorage.setItem('selectLengthTime', selectLengthTime);
            localStorage.setItem('selectStartTime', (new Date()).toISOString());
        }
    };

    const handleButtonClick = () => {
        if (selectLengthTime) {
            // save time
            handleTimeChange();
            // Perform any desired action with the student time
            setDialogPromptComplete(dialogPrompt.id);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    };

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
    }, []);

    React.useEffect(() => {
        if (chatBubbleDone) {
            setPromptInputComponent(
                <SendButtonTextField
                    label="Minutes"
                    value={selectLengthTime || ''}
                    onChange={handleInputChange}
                    onSubmit={handleButtonClick}
                    onKeyDown={handleKeyDown}
                    sx={{ width: '80%', flexGrow: 1 }} // Directly control the width here
                    autoFocus
                />
                // <Stack direction="row" alignItems="center" sx={{ justifyContent: 'flex-end' }} spacing={2}>
                //     <Input
                //         placeholder="Minutes"
                //         value={selectLengthTime || ''}
                //         onChange={handleInputChange}
                //         onKeyDown={handleKeyDown}
                //         autoFocus
                //     />
                //     <Button variant="contained" onClick={handleButtonClick}>
                //         Next
                //     </Button>
                // </Stack>
            )
        }
    }, [selectLengthTime, chatBubbleDone]);

    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
        </Stack>
    );
};

export default DialogPromptSelectLengthTimeComponent;
