import { AppBar, Button, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { Link } from 'react-router-dom';
import iconHome from './asset/icon-home.png';
import iconLesson from './asset/icon-lesson.png';
import iconLogout from './asset/icon-logout.png';
import iconProfile from './asset/icon-profile.png';
import iconPronouce from './asset/icon-pronounce-blue.png';
import iconSelfStudy from './asset/icon-self-study.png';
import ErrorMessageContext from './context/ErrorMessageContext';
import LoginLinkTextContext from './context/LoginLinkTextContext';

export function isJwtLoggedIn() {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
        try {
            const decodedToken: { exp: number } = jwtDecode(jwt);
            const currentTime = Date.now() / 1000;

            // Check if the token is not expired
            return currentTime < decodedToken.exp;
        } catch (error) {
            console.error('Error decoding JWT:', error);
            return false;
        }
    }
    return false;
}

export default function Menu() {

    const theme = useTheme();

    const [value, setValue] = React.useState(0); // Initialize value state

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const { loginLinkText, setLoginLinkText } = React.useContext(LoginLinkTextContext)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    let errorDiv;
    if (errorMessage !== null && errorMessage.length > 0) {
        errorDiv = <Typography variant="body1" sx={{ color: 'red', marginRight: '16px' }}>{errorMessage}</Typography>
    } else {
        errorDiv = <div />;
    };

    const handleLoginClick = () => {
        localStorage.removeItem('jwt'); // Remove 'jwt' from localStorage
        setLoginLinkText('Login')
    };

    React.useEffect(() => {
        if (isJwtLoggedIn()) {
            setLoginLinkText('Logout');
        } else {
            setLoginLinkText('Login');
        }
    });

    return (
        <AppBar position="static" elevation={0}
            sx={{
                backgroundColor: theme.palette.background.default,
                borderBottom: '1.5px solid rgba(82, 99, 125, 0.2)',
                paddingTop: '16px',
                paddingBottom: '16px',
                paddingLeft: '64px',
                paddingRight: '64px',
            }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Tabs value={value} onChange={handleChange}
                    sx={{ flex: 1 }} // Allow the tabs to take up remaining space
                >
                    <Tab
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={iconHome} alt="Home" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                Home
                            </div>
                        }
                        component={Link}
                        to="/"
                        sx={{ color: theme.palette.primary.main, fontSize: '16px' }}
                    // icon={<img src={iconHome} alt="Home" style={{ width: '24px', height: '24px', marginRight: '8px' }} />} // Use the imported icon directly
                    />
                    <Tab
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={iconLesson} alt="Home" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                Lesson
                            </div>
                        }
                        component={Link}
                        to="/lesson"
                        sx={{ color: theme.palette.primary.main, fontSize: '16px' }} />
                    <Tab
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={iconPronouce} alt="Home" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                Pronunciation
                            </div>
                        }
                        component={Link}
                        to="/sentence"
                        sx={{ color: theme.palette.primary.main, fontSize: '16px' }} />
                    <Tab
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={iconSelfStudy} alt="Home" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                Conversation
                            </div>
                        }
                        component={Link}
                        to="/chat"
                        sx={{ color: theme.palette.primary.main, fontSize: '16px' }} />
                    <Tab
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={iconSelfStudy} alt="Home" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                Office Hours
                            </div>
                        }
                        component={Link}
                        to="/office_hour"
                        sx={{ color: theme.palette.primary.main, fontSize: '16px' }} />
                    <Tab
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={iconProfile} alt="Home" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                Profile
                            </div>
                        }
                        component={Link}
                        to="/registration"
                        sx={{ color: theme.palette.primary.main, fontSize: '16px' }} />
                </Tabs>
                {errorDiv}
                {/* {loginLinkText === "Logout" && (
                    <Link
                        to="/registration"
                        style={{
                            color: theme.palette.primary.main,
                            marginRight: theme.spacing(4),
                            fontWeight: theme.typography.fontWeightBold,
                            fontSize: '16px',
                            textDecoration: 'none',
                        }}>
                        Profile
                    </Link>
                )} */}
                <Link
                    to="/login"
                    style={{
                        color: theme.palette.primary.main,
                        marginRight: theme.spacing(4),
                        fontWeight: theme.typography.fontWeightBold,
                        fontSize: '16px',
                        textDecoration: 'none',
                    }} onClick={handleLoginClick} >
                    {loginLinkText.toLowerCase() == "logout" ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={iconLogout} style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                            {loginLinkText}
                        </div>
                    ) : (<>
                        {loginLinkText.toLowerCase() == "login" ? (
                            <Button variant="outlined" sx={{
                                color: (theme) => theme.palette.primary.main, // Text color
                                borderColor: (theme) => theme.palette.primary.main, // Border color
                                borderWidth: 2,
                                '&:hover': {
                                    borderColor: (theme) => theme.palette.primary.main, // Hover border color
                                    backgroundColor: 'transparent', // Ensure the background remains transparent on hover
                                    borderWidth: 2,
                                },
                                minWidth: '128px',
                                height: '48px'
                            }}>
                                Sign In
                            </Button>
                        ) : (
                            { loginLinkText }
                        )}
                    </>)}
                </Link>
            </Stack>
        </AppBar>
    );
}
