import { Box, IconButton, Stack } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AudioApi } from '../api/AudioApi';
import { DialogPromptIntroduceWord } from '../api/DialogPromptApi';
import SpeakerBlueIcon from '../asset/icon-speaker-blue.svg';
import ErrorMessageContext from '../context/ErrorMessageContext';
import { useKaiTiFontStyles } from '../font/KaiTiFont';
import ChatBubble from './ChatBubble';

interface DialogPromptIntroduceWordComponentProps {
    dialogPrompt: DialogPromptIntroduceWord;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptIntroduceWordComponent: React.FC<DialogPromptIntroduceWordComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const kaiTiFontClass = useKaiTiFontStyles();
    const storedLearnWithCharacter = localStorage.getItem('learnWithCharacter');
    const learnWithCharacter = storedLearnWithCharacter ? JSON.parse(storedLearnWithCharacter) : false;

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    const history = useHistory();

    React.useEffect(() => {
        setPromptInputComponent(null)
    }, []);

    const handleSpeakerClick = () => {
        const teacher = localStorage.getItem('teacher');
        let audioPromise;

        if (teacher == "male") {
            audioPromise = AudioApi.getMale(dialogPrompt.sentence_id)
        } else if (teacher == 'female') {
            audioPromise = AudioApi.getFemale(dialogPrompt.sentence_id)
        } else if (teacher == 'female2') {
            audioPromise = AudioApi.getFemale2(dialogPrompt.sentence_id)
        } else {
            throw Error("unknown selected teacher name in local storage " + teacher);
        }
        7

        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.addEventListener('ended', () => { setDialogPromptComplete(dialogPrompt.id) }); // Listen to the 'ended' event
                audio.play();
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    };

    React.useEffect(() => {
        if (chatBubbleDone) {
            if (chatBubbleContainerRef.current) {
                chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            setPromptInputComponent(
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton onClick={handleSpeakerClick}>
                        <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px' }} />
                    </IconButton>
                </div>
            )
        }
    }, [chatBubbleDone]);


    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
            {chatBubbleDone && (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pb: 2 }}>
                    <Stack direction="row" alignItems="center" justifyContent="center" className="border-dark"
                        sx={{ pb: 1, pl: 2, pr: 3, display: 'inline-flex' }}>
                        <IconButton onClick={handleSpeakerClick}>
                            <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px', marginRight: '8px' }} />
                        </IconButton>
                        {dialogPrompt.pinyin && dialogPrompt.pinyin.map((pinyinElement, index) => (
                            <Stack key={index} spacing={1} alignItems="center">
                                <span style={{ marginLeft: '0.5em', marginRight: '0.5em', fontSize: '32px' }}>
                                    {pinyinElement}
                                </span>
                                {learnWithCharacter && (
                                    <div className={kaiTiFontClass.customFont} style={{ fontSize: '48px', margin: 0, padding: 0, lineHeight: 0.9 }} >
                                        <span>{dialogPrompt.new_word[index]}</span>
                                    </div>
                                )}
                            </Stack>
                        ))}
                    </Stack>
                </Box>
            )}
        </Stack>
    );
};

export default DialogPromptIntroduceWordComponent;
