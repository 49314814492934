import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface ISentence {
    id: number;
    text: string;
    pinyin: string[];
    processing_queue?: number;
}

export const SentenceApi = {
    getById: function (sentenceId: number) {
        return axiosInstance.request<ISentence>({
            method: "GET",
            url: `/sentence/${sentenceId}`
        });
    },
    createOrUpdate: function (word: string) {
        return axiosInstance.request<ISentence>({
            method: "PUT",
            url: `/sentence/${word}`
        });
    },
}
