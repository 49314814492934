import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CourseApi, ICourse } from '../api/CourseApi';
import ErrorMessageContext from '../context/ErrorMessageContext';

interface CourseFormProps {
    courseId: number;
    onSubmit: (updatedCourse: ICourse) => void;
    onDelete: (courseId: number) => void;
}

const CourseForm: React.FC<CourseFormProps> = ({ courseId, onSubmit, onDelete }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [id, setId] = React.useState(-1);
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [orderIndex, setOrderIndex] = React.useState(0);
    const [showConfirmation, setShowConfirmation] = React.useState(false);

    React.useEffect(() => {
        CourseApi.getById(courseId)
            .then((response) => {
                const course = response.data;
                setId(course.id);
                setName(course.name);
                setDescription(course.description ? course.description : '');
                setOrderIndex(course.order_index);
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }, [courseId]);

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setId(Number(e.target.value));
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleOrderIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrderIndex(Number(e.target.value));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const updatedCourse: ICourse = {
            id,
            order_index: orderIndex,
            name,
            description
        };
        onSubmit(updatedCourse);
    };


    const handleDeleteConfirmation = () => {
        setShowConfirmation(true);
    };

    const handleDeleteCancel = () => {
        setShowConfirmation(false);
    };

    const handleDeleteConfirm = () => {
        setShowConfirmation(false);
        onDelete(courseId);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
                <Typography variant="h6">
                    Edit Course
                </Typography>
                <input type="hidden" value={id} onChange={handleIdChange} />
                <TextField
                    label="Name"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={handleDescriptionChange}
                />
                <TextField
                    label="Sequence Number"
                    value={orderIndex}
                    onChange={handleOrderIndexChange}
                    type="number"
                    required
                />
                <br />
                <Button type="submit" variant="contained">
                    Save
                </Button>
                <Button variant="contained" color="error" onClick={handleDeleteConfirmation}>
                    Delete
                </Button>
            </Stack>
            <Dialog open={showConfirmation} onClose={handleDeleteCancel}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Delete Course</Typography></DialogTitle>
                <DialogContent>
                    <Typography sx={{ color: '#8D8D8D' }}>Are you sure you want to delete this course?</Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleDeleteCancel} sx={{ color: '#787878' }}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} sx={{ color: '#787878' }} autoFocus>Delete</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default CourseForm;
