import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CourseApi } from '../api/CourseApi';
import { ILesson } from '../api/LessonApi';
import ErrorMessageContext from '../context/ErrorMessageContext';

interface LessonPageProps {
    lesson: ILesson;
}

const LessonPage: React.FC<LessonPageProps> = ({ lesson }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [courseName, setCourseName] = React.useState("");
    const [lessonName, setLessonName] = React.useState(lesson.name);
    const [lessonDescription, setLessonDescription] = React.useState(lesson.description);

    const history = useHistory();

    React.useEffect(() => {
        setLessonName(lesson.name);
        setLessonDescription(lesson.description);

        CourseApi.getById(lesson.course_id)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to CourseApi.getById() " + response.status)
            })
            .then(course => setCourseName(course.name))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })

    }, [lesson]);

    return (
        <Stack spacing={4} sx={{ margin: '0 auto', pt: 2 }}>
            <Typography>
                {lessonDescription}
            </Typography>
        </Stack>
    );
};

export default LessonPage;
