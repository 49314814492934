import React from 'react';
import { Box, TextField, IconButton, useTheme, SxProps, Theme, CircularProgress } from '@mui/material';
import SendBlueIcon from '../asset/icon-send-blue-on-white.png';

// Define props interface if you're using TypeScript
interface SendButtonTextFieldProps {
    name?: string;
    label?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    isLoading?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    sx?: SxProps<Theme>;
}

const SendButtonTextField: React.FC<SendButtonTextFieldProps> = ({
    name = null,
    label = null,
    value,
    onChange,
    onSubmit,
    onKeyDown,
    isLoading = false,
    autoFocus = false,
    required = false,
    disabled = false,
    sx,
}) => {

    const theme = useTheme();

    return (
        <Box className="border-light"
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%', // Match TextField's width
                py: 1.5, // Adding padding inside the Box for better spacing
                pl: 2,
                m: 0,
                backgroundColor: theme.palette.background.default, // Set the background color to light blue
                ...sx, // Spread the sx prop here
            }}
        >
            <TextField
                name={name || undefined}
                required={required}
                fullWidth
                id={name || undefined} // Generally, the id can be the same as name for simplicity
                label={label || undefined}
                autoFocus={autoFocus}
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                    sx: {
                        border: 'none',
                        backgroundColor: theme.palette.background.default, // Set the background color to light blue
                        '&.Mui-focused': {
                            backgroundColor: theme.palette.background.default, // Maintain the color even when focused
                        },
                        '& .MuiInputBase-input': {
                            color: "inherit",
                            backgroundColor: theme.palette.background.default, // Ensure the input maintains the background color
                        },
                        "& .MuiInputBase-root": {
                            color: "inherit",
                            "&:before, &:after": {
                                display: 'none',
                            },
                        },
                    }
                }}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
            {isLoading ? (
                <CircularProgress size={36} sx={{ mr: 2 }} />
            ) : (
                <IconButton type="submit" disabled={disabled} onClick={onSubmit}>
                    <img src={SendBlueIcon} style={{ maxWidth: '36px', maxHeight: '36px' }} />
                </IconButton>
            )}
        </Box>
    );
};

export default SendButtonTextField;
