import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ICourse } from '../api/CourseApi';
import { DialogApi } from '../api/DialogApi';
import { DialogPrompt, DialogPromptApi } from '../api/DialogPromptApi';
import { LessonApi } from '../api/LessonApi';
import ErrorMessageContext from '../context/ErrorMessageContext';
import { DialogPromptComponentFactory } from './DialogPage';

interface SettingsPageProps {
    course: ICourse;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ course }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const [dialogPromptList, setDialogPromptList] = React.useState<DialogPrompt[]>([]);

    const [promptInputComponent, setPromptInputComponent] = React.useState<React.ReactNode>(null);

    const chatBubbleContainerRef = React.useRef<HTMLDivElement>(null);

    const history = useHistory();

    React.useEffect(() => {
        LessonApi.getAll(course.id)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to LessonApi.getAll() " + response.status)
            })
            .then(list => {
                DialogApi.getAll(list[0].id)
                    .then(response => {
                        if (response.status < 300) {
                            return response.data;
                        }
                        throw new Error("Unable to DialogApi.getAll() " + response.status)
                    })
                    .then(list => {
                        DialogPromptApi.getAll(list[0].id)
                            .then(response => {
                                if (response.status < 300) {
                                    return response.data;
                                }
                                throw new Error("Unable to DialogPromptApi.getAll() " + response.status)

                            })
                            .then(list => {
                                setDialogPromptList(list)
                                setCurrentDialogPromptIndex(1)
                            })
                            .catch(err => {
                                if (err.response && err.response.status === 401) {
                                    history.push('/login');
                                } else {
                                    console.error(err);
                                    setErrorMessage(err.message);
                                }
                            })
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            history.push('/login');
                        } else {
                            console.error(err);
                            setErrorMessage(err.message);
                        }
                    })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }, []);

    const [currentDialogPromptIndex, setCurrentDialogPromptIndex] = React.useState(1);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentDialogPromptIndex]);

    const setDialogPromptComplete = () => {
        setCurrentDialogPromptIndex(prevIndex => prevIndex + 1);
    }

    const addDialog = (dialogId: number) => {
        DialogPromptApi.getAll(dialogId)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPromptApi.getAll() " + response.status)

            })
            .then(newList => {
                setDialogPromptList((prevList) => [...prevList, ...newList]);
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }

    const selectTeacherIndex = dialogPromptList.findIndex(
        (dialogPrompt) => dialogPrompt.class_name === "DialogPromptSelectTeacher"
    );

    return (
        <Stack spacing={2} sx={{ margin: '0 auto', pt: 2 }}>
            <div style={{ height: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <Typography variant="h4">
                    {course.name}
                </Typography>
                {dialogPromptList.slice(0, currentDialogPromptIndex).map((dialogPrompt, index) => {
                    const showAvatar = index > selectTeacherIndex;
                    return DialogPromptComponentFactory(dialogPrompt, setDialogPromptComplete, setPromptInputComponent, addDialog, chatBubbleContainerRef, showAvatar)
                })}
                <div ref={chatBubbleContainerRef} />
            </div>
            <Divider sx={{ my: '1em' }} />
            <div style={{ marginBottom: '1em' }}>
                {promptInputComponent}
            </div>

        </Stack>
    );
};

export default SettingsPage;
