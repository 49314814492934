import { Box, Button, Stack, Typography } from '@mui/material';
import { shuffle } from 'lodash'; // Importing the shuffle function from a library like Lodash
import React from 'react';
import { DialogPromptMatchWordPicture } from '../api/DialogPromptApi';
import ChatBubble from './ChatBubble';


interface DialogPromptMatchWordPictureComponentProps {
    dialogPrompt: DialogPromptMatchWordPicture;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptMatchWordPictureComponent: React.FC<DialogPromptMatchWordPictureComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    const [componentList, setComponentList] = React.useState<any[]>([]);
    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
    const [chatBubbleCorrectDone, setChatBubbleCorrectDone] = React.useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
    const [shuffledQuestionIndexList, setShuffledQuestionIndexList] = React.useState<number[]>([]);
    const [currentVocabPrompt, setCurrentVocabPrompt] = React.useState('teacher_english_prompt');

    const studentAvatar = localStorage.getItem('studentAvatar');

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);

        // randomize questions
        const n = dialogPrompt.vocabulary_word_list.length;
        const positiveNumbers = Array.from({ length: n }, (_, index) => index + 1);
        const negativeNumbers = Array.from({ length: n }, (_, index) => -index - 1);
        const combinedList = positiveNumbers.concat(negativeNumbers);
        const shuffledCombinedList = shuffle(combinedList);
        // if we have same word in a row, swap it with next.
        if (shuffledCombinedList.length > 2) {
            for (let i = 0; i < shuffledCombinedList.length - 1; i++) {
                if (Math.abs(shuffledCombinedList[i]) == Math.abs(shuffledCombinedList[i + 1])) {
                    if (i == 0) {
                        const swap = shuffledCombinedList[i + 1];
                        shuffledCombinedList[i + 1] = shuffledCombinedList[i + 2];
                        shuffledCombinedList[i + 2] = swap;
                    } else {
                        const swap = shuffledCombinedList[i];
                        shuffledCombinedList[i] = shuffledCombinedList[i - 1];
                        shuffledCombinedList[i - 1] = swap;
                    }
                }
            }

        }
        setShuffledQuestionIndexList(shuffledCombinedList); // Assuming you have a shuffle function (e.g., from Lodash)
    }, []);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [componentList])

    let answerList: any[] = []

    React.useEffect(() => {
        if (chatBubbleDone) {

            if (dialogPrompt.vocabulary_word_list.length == 0) {
                setChatBubbleDone(false);
                setCurrentVocabPrompt('done');
                setChatBubbleCorrectDone(true);
                return;
            }

            const currentQuestion = shuffledQuestionIndexList[currentQuestionIndex];
            const vocabularyWord = dialogPrompt.vocabulary_word_list[Math.abs(currentQuestion) - 1];
            const displayImageSelectWord = currentQuestion > 0;
            // console.log("currentQuestionIndex=" + currentQuestionIndex + " currentQuestion=" + currentQuestion + " vocabularyWord=" + vocabularyWord.word + " displayImageSelectWord=" + displayImageSelectWord + " currentVocabPrompt=" + currentVocabPrompt)
            if (currentVocabPrompt == 'teacher_english_prompt') {
                if (vocabularyWord.teacher_english_prompt) {
                    setChatBubbleDone(false);
                    const newChatBubble = (
                        <ChatBubble
                            isTeacher={true}
                            message={vocabularyWord.teacher_english_prompt}
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            setChatBubbleDone={setChatBubbleDone}
                        />
                    );
                    setComponentList((prevComponentList) => [...prevComponentList, newChatBubble]);
                }
                setCurrentVocabPrompt('teacher_chinese_prompt')
            } else if (currentVocabPrompt == 'teacher_chinese_prompt') {
                if (vocabularyWord.teacher_chinese_prompt) {
                    setChatBubbleDone(false);
                    const newChatBubble = (
                        <ChatBubble
                            isTeacher={true}
                            message={vocabularyWord.teacher_chinese_prompt}
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            setChatBubbleDone={setChatBubbleDone}
                            useChineseFont={true}
                        />
                    );
                    setComponentList((prevComponentList) => [...prevComponentList, newChatBubble]);
                }
                setCurrentVocabPrompt('student_answer')
            } else if (currentVocabPrompt == "student_answer") {
                setChatBubbleDone(false);
                if (displayImageSelectWord) {
                    const promptImage = <img
                        src={vocabularyWord.image}
                        alt={vocabularyWord.word}
                        key={vocabularyWord.id}
                        style={{
                            maxHeight: '200px', maxWidth: '200px',
                            height: 'auto', width: 'auto',
                            margin: '0 auto', // Add this line to center the image horizontally
                            display: 'block', // Add this line to ensure the margin works correctly
                        }}

                    />
                    let newChatBubble = <></>
                    if (vocabularyWord.student_response_prompt && vocabularyWord.student_response_prompt.trim().toLowerCase() != "<answer/>") {
                        newChatBubble =
                            <ChatBubble
                                isTeacher={false}
                                message={vocabularyWord.student_response_prompt}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                useChineseFont={true}
                            />
                    };
                    setComponentList((prevComponentList) => [...prevComponentList, promptImage, newChatBubble]);
                    const wordAnswerList = dialogPrompt.vocabulary_word_list.map((vocabularyWordOption, index) => (
                        <Button
                            variant="contained"
                            key={vocabularyWordOption.id}
                            onClick={() => handleAnswerClick(vocabularyWordOption.id, vocabularyWordOption.id === vocabularyWord.id)}
                        >
                            <Typography style={{ fontFamily: 'KaiTi', fontSize: '24px' }}>
                                {vocabularyWordOption.word}
                            </Typography>
                        </Button>
                    ));
                    answerList = shuffle(wordAnswerList.filter(Boolean)); // Shuffle the non-null components
                    setPromptInputComponent(
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                            {answerList}
                        </Stack>
                    )
                } else {
                    let sentence = vocabularyWord.word;
                    if (vocabularyWord.student_response_prompt && vocabularyWord.student_response_prompt.length > 0) {
                        sentence = vocabularyWord.student_response_prompt.replace(/<Answer\/>/g, vocabularyWord.word)
                    }
                    const newChatBubble = (
                        <ChatBubble
                            isTeacher={true}
                            message={sentence}
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            useChineseFont={true}
                        />
                    );
                    setComponentList((prevComponentList) => [...prevComponentList, newChatBubble]);
                    const imageList = dialogPrompt.vocabulary_word_list.map((vocabularyWordOption, index) => (
                        <img
                            src={vocabularyWordOption.image}
                            alt={vocabularyWordOption.word}
                            key={vocabularyWordOption.id}
                            onClick={() => handleAnswerClick(vocabularyWordOption.id, vocabularyWordOption.id == vocabularyWord.id)}
                            style={{ maxHeight: '125px', maxWidth: '125px', height: 'auto', width: 'auto' }}
                        />
                    ));
                    answerList = shuffle(imageList.filter(Boolean)); // Shuffle the non-null components
                    setPromptInputComponent(
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                            {answerList}
                        </Stack>
                    )

                }
                setCurrentVocabPrompt('done')
            }
        }
    }, [chatBubbleDone, currentVocabPrompt, currentQuestionIndex])

    const handleAnswerClick = (vocabularyWordId: number, correct: boolean) => {
        const currentQuestion = shuffledQuestionIndexList[currentQuestionIndex];
        const displayImageSelectWord = currentQuestion > 0;

        const foundAnswer = answerList.find((answer) => answer.key == vocabularyWordId);
        let foundAnswerCopy;
        if (displayImageSelectWord) {
            foundAnswerCopy = <ChatBubble
                isTeacher={false}
                message={foundAnswer.props.children.props.children}
                showAvatar={false} // avatar manually added below
                chatBubbleContainerRef={chatBubbleContainerRef}
                useChineseFont={true}
            />
        } else {
            foundAnswerCopy = (
                <img
                    src={foundAnswer.props.src}
                    alt={foundAnswer.props.alt}
                    key={foundAnswer.key}
                    style={{ maxHeight: '200px', maxWidth: '200px', height: 'auto', width: 'auto' }}
                />
            )
        }
        const stackFoundAnswerCopy =
            <Stack direction="row" alignItems="center" justifyContent="right" spacing={2}>
                {foundAnswerCopy}
                <img
                    src={studentAvatar || ''}
                    style={{ maxHeight: '4em', maxWidth: '4em' }}
                />
            </Stack>
        if (correct) {
            if (currentQuestionIndex + 1 >= shuffledQuestionIndexList.length) {
                setChatBubbleCorrectDone(true);
                setPromptInputComponent(<></>)
            } else {
                setCurrentVocabPrompt('teacher_english_prompt')
                setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
            }

            const newChatBubble = (
                <ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.yes_response}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setChatBubbleDone}
                />
            );
            setComponentList((prevComponentList) => [...prevComponentList, stackFoundAnswerCopy, newChatBubble]);
        } else {
            answerList = shuffle(answerList.filter(Boolean)); // Shuffle the non-null components
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                    {answerList}
                </Stack>
            )
            setCurrentVocabPrompt('student_match')

            const newChatBubble = (
                <ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.no_response}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setChatBubbleDone}
                />
            );
            setComponentList((prevComponentList) => [...prevComponentList, stackFoundAnswerCopy, newChatBubble]);
        }
    }

    React.useEffect(() => {
        if (chatBubbleCorrectDone) {
            setDialogPromptComplete(dialogPrompt.id)
        }
    }, [chatBubbleCorrectDone])

    return (
        <Stack spacing={2}>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
            {componentList.map((component, index) => (
                <Box key={index} component="div">
                    {component}
                </Box>
            ))}
        </Stack>
    );
};

export default DialogPromptMatchWordPictureComponent;
