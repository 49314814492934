import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { shuffle } from 'lodash'; // Importing the shuffle function from a library like Lodash
import React from 'react';
import { DialogPromptQuizQuestionAudio } from '../api/DialogPromptApi';
import ChatBubble from './ChatBubble';
import { AudioApi } from '../api/AudioApi';
import ErrorMessageContext from '../context/ErrorMessageContext';
import { useHistory } from 'react-router-dom';
import SpeakerBlueIcon from '../asset/icon-speaker-blue.svg';


interface DialogPromptQuizQuestionAudioComponentProps {
    dialogPrompt: DialogPromptQuizQuestionAudio;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptQuizQuestionAudioComponent: React.FC<DialogPromptQuizQuestionAudioComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    const [componentList, setComponentList] = React.useState<any[]>([]);
    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
    const [chatBubbleCorrectDone, setChatBubbleCorrectDone] = React.useState(false);

    const studentAvatar = localStorage.getItem('studentAvatar');
    const [vocabularyWordId, setVocabularyWordId] = React.useState<number | undefined>();

    // sometimes state not always up to date. reference always up to date.
    const vocabularyWordIdRef = React.useRef(vocabularyWordId);
    vocabularyWordIdRef.current = vocabularyWordId;

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    let answerList: any[] = []

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
    }, []);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [componentList])

    React.useEffect(() => {
        if (chatBubbleDone) {

            setChatBubbleDone(false);
            const newComponent: any[] = [];
            if (dialogPrompt.image_src) {
                newComponent.push(<img
                    src={dialogPrompt.image_src}
                    alt={dialogPrompt.prompt}
                    style={{
                        maxHeight: '400px', maxWidth: '400px',
                        height: 'auto', width: 'auto',
                        margin: '0 auto', // Add this line to center the image horizontally
                        display: 'block', // Add this line to ensure the margin works correctly
                    }}
                />)
            }

            if (dialogPrompt.teacher_chinese_prompt != null && dialogPrompt.teacher_chinese_prompt.trim().length > 0) {
                newComponent.push(<ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.teacher_chinese_prompt}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />);
            }
            setComponentList((prevComponentList) => [...prevComponentList, ...newComponent]);
        }

        const allWordList = [...dialogPrompt.incorrect_vocabulary_word_list];
        allWordList.push(dialogPrompt.correct_vocabulary_word);

        const wordAnswerList = allWordList.map((vocabularyWordOption, index) => (
            <label htmlFor={"option" + vocabularyWordOption.id} key={vocabularyWordOption.id}>
                <Stack key={vocabularyWordOption.id} direction="row" alignItems="center">
                    <Radio value={vocabularyWordOption.id} id={"option" + vocabularyWordOption.id} />
                    <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px', marginRight: '8px' }}
                        onClick={() => { handleSpeakerClick(vocabularyWordOption.id) }}
                    />
                    <Stack direction="column" alignItems="center">
                        {vocabularyWordOption.word ?
                            <Typography style={{ fontFamily: 'KaiTi', fontSize: '24px' }}>
                                {vocabularyWordOption.word}
                            </Typography>
                            :
                            <>{/* create empty child so foundAnswer.props.children[0] works */}</>
                        }
                        {vocabularyWordOption.image ?
                            <img
                                src={vocabularyWordOption.image}
                                alt={vocabularyWordOption.word}
                                key={vocabularyWordOption.id}
                                style={{ maxHeight: '125px', maxWidth: '125px', height: 'auto', width: 'auto' }}
                            />
                            :
                            <>{/* create empty child so foundAnswer.props.children[0] works */}</>
                        }
                    </Stack>
                </Stack>
            </label>
        ));
        answerList = shuffle(wordAnswerList.filter(Boolean)); // Shuffle the non-null components
        setPromptInputComponent(<>
            <RadioGroup
                aria-label="options"
                name="options"
                value={vocabularyWordId}
                onChange={handleVocabularyWordChange}
            >
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                    {answerList}
                    <Button type="submit" variant="contained" color="primary" onClick={handleAnswerClick}>
                        Submit
                    </Button>
                </Stack>
            </RadioGroup>
        </>
        )
    }, [chatBubbleDone])

    const handleVocabularyWordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVocabularyWordId(Number(event.target.value));
    };


    const handleSpeakerClick = (vocabularyWordId: number) => {
        const teacher = localStorage.getItem('teacher');
        let audioPromise;

        const allWordList = [...dialogPrompt.incorrect_vocabulary_word_list];
        allWordList.push(dialogPrompt.correct_vocabulary_word);
        const vocabularyWord = allWordList.find(w => w.id == vocabularyWordId)

        if (vocabularyWord) {
            if (teacher == "male") {
                audioPromise = AudioApi.getMale(vocabularyWord.audio_sentence_id)
            } else if (teacher == 'female') {
                audioPromise = AudioApi.getFemale(vocabularyWord.audio_sentence_id)
            } else if (teacher == 'female2') {
                audioPromise = AudioApi.getFemale2(vocabularyWord.audio_sentence_id)
            } else {
                throw Error("unknown selected teacher name in local storage " + teacher);
            }
        } else {
            throw Error("Vocabulary word Id not found " + vocabularyWordId);
        }

        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.play();
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    };

    const handleAnswerClick = () => {

        if (vocabularyWordIdRef.current) {

            const allWordList = [...dialogPrompt.incorrect_vocabulary_word_list];
            allWordList.push(dialogPrompt.correct_vocabulary_word);
            const vocabularyWord = allWordList.find(w => w.id == vocabularyWordIdRef.current)!

            const foundAnswerChatBubbleCopy =
                <Stack direction="row" alignItems="center" justifyContent="right">
                    <div style={{ flex: 1, maxWidth: '300px' }}>
                        <Stack direction="row" alignItems="center" justifyContent="right" spacing={2}>
                            <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px', marginRight: '8px' }}
                                onClick={() => { handleSpeakerClick(vocabularyWord.id) }}
                            />
                            <Stack direction="column" alignItems="center">
                                {vocabularyWord.word ?
                                    <Typography style={{ fontFamily: 'KaiTi', fontSize: '24px' }}>
                                        {vocabularyWord.word}
                                    </Typography>
                                    :
                                    <>{/* create empty child so foundAnswer.props.children[0] works */}</>
                                }
                                {vocabularyWord.image ?
                                    <img
                                        src={vocabularyWord.image}
                                        alt={vocabularyWord.word}
                                        key={vocabularyWord.id}
                                        style={{ maxHeight: '125px', maxWidth: '125px', height: 'auto', width: 'auto' }}
                                    />
                                    :
                                    <>{/* create empty child so foundAnswer.props.children[0] works */}</>
                                }
                            </Stack>
                            <img
                                src={studentAvatar || ''}
                                style={{ maxHeight: '4em', maxWidth: '4em', marginRight: '16px' }}
                            />
                        </Stack>
                    </div>
                </Stack>

            const additionalComponentList: React.ReactNode[] = []
            additionalComponentList.push(foundAnswerChatBubbleCopy)

            //     // <Stack direction="row" alignItems="center" justifyContent="right" spacing={2}>
            //     //     <div style={{ flex: 1, maxWidth: '300px' }}>
            //     //         <ChatBubble
            //     //             isTeacher={false}
            //     //             message={foundAnswer.props.children[0].props.children.props.children}
            //     //             showAvatar={false} // avatar manually added below
            //     //             chatBubbleContainerRef={chatBubbleContainerRef}
            //     //             useChineseFont={true}
            //     //         />
            //     //     </div>
            //     //     <img
            //     //         src={studentAvatar || ''}
            //     //         style={{ maxHeight: '4em', maxWidth: '4em' }}
            //     //     />

            //     // </Stack>
            // }
            // if (foundAnswer.props.children[1].props.src) {
            //     foundAnswerChatBubbleCopy =
            //         <Stack direction="row" alignItems="center" justifyContent="right" spacing={2}>
            //             <img
            //                 src={foundAnswer.props.children[1].props.src}
            //                 alt={foundAnswer.props.children[1].props.alt}
            //                 key={foundAnswer.props.children[1].key}
            //                 style={{ maxHeight: '200px', maxWidth: '200px', height: 'auto', width: 'auto' }}
            //             />
            //             {foundAnswerChatBubbleCopy == null && (
            //                 <img
            //                     src={studentAvatar || ''}
            //                     style={{ maxHeight: '4em', maxWidth: '4em' }}
            //                 />
            //             )}
            //             {foundAnswerChatBubbleCopy !== null && (
            //                 <div style={{ width: '4em' }}></div> // Spacer with the same width as the img
            //             )}
            //         </Stack>
            //     additionalComponentList.push(foundAnswerChatBubbleCopy)
            // }
            const correct = vocabularyWordIdRef.current == dialogPrompt.correct_vocabulary_word.id
            if (correct) {
                setPromptInputComponent(<></>)
                const newChatBubble = (
                    <ChatBubble
                        isTeacher={true}
                        message={dialogPrompt.yes_response}
                        showAvatar={showAvatar}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                        setChatBubbleDone={setChatBubbleCorrectDone}
                    />
                );
                setComponentList((prevComponentList) => [...prevComponentList, ...additionalComponentList, newChatBubble]);
            } else {
                answerList = shuffle(answerList.filter(Boolean)); // Shuffle the non-null components
                setPromptInputComponent(<>
                    <RadioGroup
                        aria-label="options"
                        name="options"
                        value={vocabularyWordId}
                        onChange={handleVocabularyWordChange}
                    >
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                            {answerList}
                            <Button type="submit" variant="contained" color="primary" onClick={handleAnswerClick}>
                                Submit
                            </Button>
                        </Stack>
                    </RadioGroup>
                </>)

                const newChatBubble = (
                    <ChatBubble
                        isTeacher={true}
                        message={dialogPrompt.no_response}
                        showAvatar={showAvatar}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                );
                setComponentList((prevComponentList) => [...prevComponentList, ...additionalComponentList, newChatBubble]);
            }
        }

    }

    React.useEffect(() => {
        if (chatBubbleCorrectDone) {
            setDialogPromptComplete(dialogPrompt.id)
        }
    }, [chatBubbleCorrectDone])

    return (
        <Stack spacing={2}>
            <Stack sx={{ marginleft: "16px"}}>
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={dialogPrompt.prompt}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleDone}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            </Stack>
            {componentList.map((component, index) => (
                <Box key={index} component="div">
                    {component}
                </Box>
            ))}
        </Stack>
    );
};

export default DialogPromptQuizQuestionAudioComponent;
