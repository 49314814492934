import * as React from 'react';

export interface ILoginLinkTextContextType {
  loginLinkText: string;
  setLoginLinkText: (newLoginLinkText: string) => void;
};

export const LoginLinkTextInitialContext = { loginLinkText: "Login", setLoginLinkText: () => null };

const LoginLinkTextContext = React.createContext<ILoginLinkTextContextType>(LoginLinkTextInitialContext);

export default LoginLinkTextContext;
