import '../style/global.css';

import { Button, Container, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory from React Router
import { LoginApi } from '../api/LoginApi';
import background from '../asset/background-resetpassword.png';

export default function ResetPasswordEmail() {

    const [email, setEmail] = useState('');
    const [statusCode, setStatusCode] = useState(999);

    const history = useHistory(); // Get the history object

    const handleSendEmail = () => {
        LoginApi.sendResetPasswordEmail(email)
            .then(response => {
                if (response.status < 300) {
                    setStatusCode(response.status)
                } else {
                    throw new Error("Unable to LoginApi.register() " + response.status)
                }
            })
            .catch(error => {
                // Handle registration error
                console.error(error);
            });
    };

    const handleCancel = () => {
        history.goBack(); // Go back to the previous page
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${background})`, // Replace 'path_to_your_image.png' with the actual path to your image
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'auto 100%',
                backgroundPosition: 'right',
                minHeight: 'calc(100vh - 107px)', // Set the container's minimum height to fill the entire viewport, minus menu
                width: '100vw', // Adjusted width to fill the entire viewport horizontally
                padding: 0,
            }}
        >
            <div
                style={{
                    width: '480px' // Set the fixed width
                }}
            >
                {statusCode < 300 ? (<>
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: 'left', // Align the header text to the left
                            color: (theme) => theme.palette.primary.main,
                            mb: 4, // Add some margin at the bottom for spacing
                        }}
                    >
                        The reset password email has been sent.
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'left', // Ensure the text is left-aligned
                            margin: 0, // Remove the default margin for consistency in styling
                            fontWeight: 400
                        }}
                    >
                        We have sent you an email to verify updated email address.
                        Please check your mail and click the verification link to verify it.
                    </Typography>
                </>) : (

                    <Container maxWidth="xs">
                        <Typography variant="h2" sx={{ mb: 2 }}>
                            Forgot Password
                        </Typography>
                        <form>
                            {/* Input fields */}
                            <TextField
                                label="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                fullWidth
                                margin="normal"
                                required
                                autoFocus
                            />

                            {/* Buttons */}
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2em', marginTop: '1em' }}>
                                <Button fullWidth variant="contained" color="primary" onClick={handleSendEmail}>
                                    Send Email
                                </Button>
                                {/* <Button variant="contained" color="error" onClick={handleCancel}>
                                Cancel
                            </Button> */}
                            </div>
                        </form>
                    </Container>
                )}
            </div>
        </div>
    )
};
