import { Button, Link, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory from React Router
import { IRegister, LoginApi } from '../api/LoginApi';
import { IUser, UserApi } from '../api/UserApi';
import background from '../asset/background-signup.png';
import { isJwtLoggedIn } from '../Menu';

export default function Registration() {


    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [registerStatusCode, setRegisterStatusCode] = useState(999);
    const [userMessage, setUserMessage] = useState('');
    const [validationErrorMessage, setValidationErrorMessage] = useState('');

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState<IUser | null>(null);

    const history = useHistory(); // Get the history object

    React.useEffect(() => {
        if (isJwtLoggedIn()) {
            setLoggedIn(true)
            UserApi.get()
                .then(response => {
                    // Handle the response
                    const user: IUser = response.data;
                    if (user) {
                        setUser(user);
                        if (user.email) setEmail(user.email)
                        if (user.first_name) setFirstName(user.first_name)
                        if (user.last_name) setLastName(user.last_name)
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setUserMessage("")
                        setValidationErrorMessage('Error getting user:' + err.response.data);
                    }
                });
        } else {
            setLoggedIn(false)
        }
    }, []);

    const handleRegister = () => {
        if (isLoggedIn) {
            if (password !== confirmPassword) {
                setUserMessage("");
                setValidationErrorMessage("Passwords do not match");
            } else {
                const userData: IUser = {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    current_password: currentPassword,
                    password: password,
                };
                UserApi.update(userData)
                    .then(response => {
                        if (response.status < 300) {
                            setUserMessage("Save successful");
                            setValidationErrorMessage("");
                        } else {
                            throw new Error("Unable to LoginApi.register() " + response.status)
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            history.push('/login');
                        } else {
                            console.error(err);
                            setUserMessage("");
                            setValidationErrorMessage(err.response.data);
                        }
                    });
            }
        } else {
            const referrerSentenceScoreId = localStorage.getItem('s');
            const referrerGuid = localStorage.getItem('r');
            const registerData: IRegister = {
                email,
                password,
                confirmPassword,
                firstName,
                lastName,
                referrerSentenceScoreId,
                referrerGuid
            };

            LoginApi.register(registerData)
                .then(response => {
                    if (response.status < 300) {
                        setRegisterStatusCode(response.status)
                    } else {
                        throw new Error("Unable to LoginApi.register() " + response.status)
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setUserMessage("");
                        setValidationErrorMessage('Error registering user:' + err.response.data);
                    }
                });

        }
    };

    // fix some strange re-rendering problem
    const [validateEmailClicked, setValidateEmailClicked] = useState(false);
    const handleValidateEmail = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // Prevent the default navigation behavior
        setValidateEmailClicked(true);
    }
    React.useEffect(() => {
        if (validateEmailClicked) {
            UserApi.sendValidateEmail()
                .then(response => {
                    // console.log('Promise resolved:', response);
                    setUserMessage("Validation email has been sent");
                    setValidationErrorMessage("");
                    setValidateEmailClicked(false);
                })
                .catch(error => {
                    // console.log('Promise rejected:', error);
                    setUserMessage("");
                    setValidationErrorMessage("Error sending validation email");
                    setValidateEmailClicked(false);
                })
        }
    }, [validateEmailClicked]);

    return (

        <div style={{
            display: 'flex',
            flexDirection: 'column', // Changed to stack children vertically
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${background})`, // Replace 'path_to_your_image.png' with the actual path to your image
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            backgroundPosition: 'end',
            minHeight: 'calc(100vh - 82px)', // Set the container's minimum height to fill the entire viewport, minus menu
            width: '100vw', // Adjusted width to fill the entire viewport horizontally
            padding: 0,
        }}>
            {!isLoggedIn && (<>
                <Typography variant="h4">We need your help!</Typography>
                <Typography variant="h5">If you find this site useful, please register a <span style={{ color: '#E6B800 ', fontWeight: 'bold' }}>free account</span> to let us know</Typography>
            </>)}
            {registerStatusCode < 300 ? (
                <Typography style={{ textAlign: 'center' }}>
                    {registerStatusCode == 201 ? ("Registration Successful!") :
                        ("Email already exists.")}
                    <br />
                    <br />
                    Please{' '}<Link href="/login">Login</Link>
                </Typography>
            ) : (<>{validationErrorMessage ? (
                <Typography variant="h3" color="error" style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }} >
                    {validationErrorMessage}
                </Typography>

            ) : (<>{userMessage ? (
                <Typography variant="h3" style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }} >
                    {userMessage}
                </Typography >

            ) : (<>
                <form>
                    {/* Input fields */}
                    <div style={{ display: 'flex', gap: '1em' }}>
                        <TextField
                            label="First Name"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Last Name"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                        autoFocus
                    />
                    {isLoggedIn && (<>
                        <Typography>
                            <Link
                                component="button"
                                onClick={handleValidateEmail}
                            >
                                Send validation email again.
                            </Link>
                            &nbsp;&nbsp;If changing email, click update first.
                        </Typography>
                        <TextField
                            label="Current Password"
                            value={currentPassword}
                            onChange={e => setCurrentPassword(e.target.value)}
                            type="password"
                            fullWidth
                            required
                            margin="normal"
                        />
                    </>)}
                    <div style={{ display: 'flex', gap: '1em' }}>
                        <TextField
                            label="New Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            fullWidth
                            required={!isLoggedIn} // Set required to true if isLoggedIn is false
                            margin="normal"
                        />
                    </div>
                    <div style={{ display: 'flex', gap: '1em' }}>
                        <TextField
                            label="Confirm New Password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            type="password"
                            fullWidth
                            required={!isLoggedIn} // Set required to true if isLoggedIn is false
                            margin="normal"
                        />
                    </div>

                    {/* Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '2em', marginTop: '1em' }}>
                        <Button variant="contained" fullWidth color="primary" onClick={handleRegister}>
                            {isLoggedIn ? "Update" : "Register"}
                        </Button>
                        {/* <Button variant="contained" color="error" onClick={handleCancel}>
                        Cancel
                    </Button> */}
                    </div>
                </form>
            </>
            )}</>)

            }</>)

            }
        </div >
    );
};
