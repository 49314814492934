import React from 'react';
import { DialogPrompt } from '../api/DialogPromptApi';
import ChatBubble from './ChatBubble';

interface DialogPromptComponentProps {
    dialogPrompt: DialogPrompt;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptComponent: React.FC<DialogPromptComponentProps> = ({
    dialogPrompt,
    setDialogPromptComplete,
    setPromptInputComponent,
    showAvatar = true,
    chatBubbleContainerRef
}) => {

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    React.useEffect(() => {
        setPromptInputComponent(null);
    }, []);

    React.useEffect(() => {
        if (chatBubbleDone) {
            setDialogPromptComplete(dialogPrompt.id);
        }
    }, [chatBubbleDone]);

    return (
        <ChatBubble
            isTeacher={dialogPrompt.is_teacher}
            message={dialogPrompt.prompt}
            showAvatar={showAvatar}
            setChatBubbleDone={setChatBubbleDone}
            chatBubbleContainerRef={chatBubbleContainerRef}
        />
    );
};

export default DialogPromptComponent;
